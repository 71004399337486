<template>
  <div class="incidents">
    <div class="page-header">
      <h1 class="page-title">Incidents</h1>
      <div class="actions">
        <!-- Search -->
        <a-input-search
          size="large"
          placeholder="Search incidents"
          v-model="search_query"
        />
        <!-- / Search -->

        <a-button
          class="button-margin-left btn-rounded"
          size="large"
          @click.prevent="$router.push('/closed-incidents')"
          >View Closed Incidents</a-button
        >

        <a-button
          class="button-margin-left btn-rounded"
          size="large"
          type="primary"
          @click.prevent="$router.push('/call-for-help')"
          >
          <!-- <img src="@/assets/shield-whie.png" width="15" style="margin-right: 10px;" /> -->
          Call For Help</a-button
        >
      </div>
    </div>

    <!-- List wrapper -->
    <div class="incidents-list-wrapper">
      <!-- Loading -->
      <div class="loader" v-if="isLoadingCombined">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No incidents -->
      <a-alert
        v-if="!isLoadingCombined && incidentsToShow.length == 0"
        type="info"
        message="No incidents to show"
      />
      <!-- / No incidents -->

      <!-- Loaded -->
      <div
        class="incidents-list"
        v-if="!isLoadingCombined && incidentsToShow.length"
      >
        <a-row :gutter="20" type="flex">
          <a-col
            :span="colSpan"
            v-for="incident in incidentsToShowOrdered"
            :key="incident.id"
          >
            <incident-list-item :incident="incident"></incident-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Incidents from "../mixins/Incidents";
import Dates from "../mixins/Dates";
import organisations from "../helpers/organisations";
const _ = require("lodash");
import IncidentListItem from "./Incidents/IncidentListItem.vue";
import RequiresNonHiddenPresences from "../mixins/RequiresNonHiddenPresences";

export default {
  name: "Incidents",
  mixins: [Incidents, Dates, RequiresNonHiddenPresences],
  components: { IncidentListItem },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  created() {
    this.setSearchQuery("");
    // this.loadIncidents();
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      isLoadingMissionControl: "isLoading",
    }),

    ...mapGetters("incidents", {
      incidentsToShow: "incidentsToShow",
      incidentsToShowOrdered: "incidentsToShowOrdered",
      isLoading: "isLoading",
      searchQuery: "searchQuery",
    }),

    isLoadingCombined() {
      return this.isLoading || this.isLoadingMissionControl;
    },

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },

    search_query: {
      set(val) {
        this.setSearchQuery(val);
      },
      get() {
        return this.searchQuery;
      },
    },
  },
  methods: {
    ...mapActions("missionControl", {
      getOrganisationById: "getOrganisationById",
    }),

    ...mapActions("incidents", {
      loadIncidents: "loadIncidents",
      setSearchQuery: "setSearchQuery",
    }),

    getOrganisationById(id) {
      return _.find(this.organisations, { id });
    },

    getIncidentUrl(incident) {
      let organisation = this.getOrganisationById(incident.ownerId);
      let tenantId = organisations.getOrganisationTenantId(organisation);
      return "/incidents/" + tenantId + "/" + incident.id;
    },
  },
};
</script>

<style scoped lang="scss">
.incident-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 10px;
}
</style>